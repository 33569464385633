import { BigNumber } from 'ethers'

export const UNIVERSAL_ROUTER_ADDRESS = (chainId: number): string => {
  if(chainId === 43113) return '0x7ae656f482783057B9Ee25105F4FaE09bD51caec'
  if(chainId === 43114) return '0x33d7B5858EC15F1E24A57abebE3AeF83570921e8'
  throw new Error(`UniversalRouter not deployed on chain ${chainId}`)
}

export const WETH_ADDRESS = (chainId: number): string => {
  if(chainId === 43113) return '0xd00ae08403B9bbb9124bB305C09058E32C39A48c'
  if(chainId === 43114) return '0xB31f66AA3C1e785363F0875A1B74E27b85FD66c7'
  throw new Error(`WETH not deployed on chain ${chainId}`)
}

export const PERMIT2_ADDRESS = '0x000000000022D473030F116dDEE9F6B43aC78BA3'

export const CONTRACT_BALANCE = BigNumber.from(2).pow(255)
export const ETH_ADDRESS = '0x0000000000000000000000000000000000000000'
export const ZERO_ADDRESS = '0x0000000000000000000000000000000000000000'
export const MAX_UINT256 = BigNumber.from(2).pow(256).sub(1)
export const MAX_UINT160 = BigNumber.from(2).pow(160).sub(1)

export const SENDER_AS_RECIPIENT = '0x0000000000000000000000000000000000000001'
export const ROUTER_AS_RECIPIENT = '0x0000000000000000000000000000000000000002'

export const OPENSEA_CONDUIT_SPENDER_ID = 0
export const SUDOSWAP_SPENDER_ID = 1
